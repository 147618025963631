import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import Channel from "../../Components/channelTalk"
import { toast } from 'react-toastify';
import axios from 'axios';

const Reset = function (props) {
    const { t } = useTranslation('translation')
    const navigate = useNavigate()
    const modalEl = useRef();

    const handleClickOutside = useCallback(({ target }) => {
        if (!modalEl.current.contains(target)) {
            props.close()
        };
    }, [props])

    const resetAccount = async () => {
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_SERVER}/user/reset`, {}, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            if (res.status === 200) {
                localStorage.removeItem("token")
                toast.info(t("dashboard_page_logout_reset_modal_toast"))
                window.removeEventListener("click", handleClickOutside);
                navigate("/")
            } else {
                toast.info(t("toast_err_working"))
            }
        } catch (err) {
            if (err.response) {
                if (err.response.status === 401) {
                    localStorage.removeItem("token")
                    toast.error(t("toast_err_token_expired"));
                    navigate('/');
                } else {
                    toast.error(t("toast_err_something_wrong"));
                    console.error(err)
                }
            } else {
                console.error(err)
                toast.error(t("toast_err_can_not_connect_server"))
            }
        }
    }

    useEffect(() => {
        window.addEventListener("click", handleClickOutside);
        return () => {
            window.removeEventListener("click", handleClickOutside);
        };
    }, [props.location, props.history, handleClickOutside]);

    return (
        <>
            <div className="modal-divver">
                <div id="modal" ref={modalEl}>
                    <div className="title">
                        {t("dashboard_page_logout_reset_modal_title_1")}<br /><span className="underscore">{t("dashboard_page_logout_reset_modal_title_2")}</span>
                    </div>
                    <div className="subtitle">{t("dashboard_page_logout_reset_modal_subtitle")}</div>
                    <div className="desc">{t("dashboard_page_logout_reset_modal_desc_1")}</div>
                    <div className="btn btn-3" onClick={resetAccount}>
                        <div>{t("dashboard_page_logout_reset_modal_btn")}</div>
                    </div>
                </div>
            </div>
        </>
    )
}

const Destroy = function (props) {
    const { t } = useTranslation('translation')
    const navigate = useNavigate()
    const modalEl = useRef();

    const handleClickOutside = useCallback(({ target }) => {
        if (!modalEl.current.contains(target)) {
            props.close()
        };
    }, [props])

    const destroyAccount = async () => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_SERVER}/user`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            localStorage.removeItem("token")
            toast.info(t("dashboard_page_logout_destroy_modal_toast"))
            window.removeEventListener("click", handleClickOutside);
            navigate("/")
        } catch (err) {
            if (err.response) {
                if (err.response.status === 401) {
                    localStorage.removeItem("token")
                    toast.error(t("toast_err_token_expired"));
                    navigate('/');
                } else {
                    toast.error(t("toast_err_something_wrong"));
                    console.error(err)
                }
            } else {
                console.error(err)
                toast.error(t("toast_err_can_not_connect_server"))
            }
        }
    }

    useEffect(() => {
        window.addEventListener("click", handleClickOutside);
        return () => {
            window.removeEventListener("click", handleClickOutside);
        };
    }, [props.location, props.history, handleClickOutside]);

    return (
        <>
            <div className="modal-divver">
                <div id="modal" ref={modalEl}>
                    <div className="title">
                        {t("dashboard_page_logout_destroy_modal_title_1")}<br /><span className="underscore">{t("dashboard_page_logout_destroy_modal_title_2")}</span>
                    </div>
                    <div className="subtitle">{t("dashboard_page_logout_destroy_modal_subtitle")}</div>
                    <div className="desc">{t("dashboard_page_logout_destroy_modal_desc_1")}</div>
                    <div className="btn btn-3" onClick={destroyAccount}>
                        <div>{t("dashboard_page_logout_destroy_modal_btn")}</div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default function DashboardLogout(props) {
    const { t } = useTranslation('translation')
    const [modal, setModal] = useState()
    const [isModalShow, setModalShow] = useState(false)
    const navigate = useNavigate()

    const logout = () => {
        localStorage.removeItem("token")
        window.scrollTo(0, 0);
        Channel.boot({
            pluginKey: "7749bc67-021a-45c9-a614-2f3c8f22949a"
        });
        navigate("/")
    }

    const showReset = () => {
        setModal(<Reset close={setModalShow} />)
        setModalShow(true)
    }

    const showDestroy = () => {
        setModal(<Destroy close={setModalShow} />)
        setModalShow(true)
    }

    return (
        <>
            <div className="card logout-menus">
                <div onClick={logout}>{t("dashboard_page_logout_logout")}</div>
                <div onClick={showReset}>{t("dashboard_page_logout_reset")}</div>
                <div onClick={showDestroy} className="warn">{t("dashboard_page_logout_destroy")}</div>
            </div>
            {isModalShow ? modal : <></>}
        </>
    )
}