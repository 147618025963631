import React from "react";
import "../css/footer.scss";
import styled from "styled-components";
import Opize from "../assets/opize.png";
import OpizeIcon from "../assets/opizeIcon.png";
import { Globe } from "phosphor-react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const FooterDivver = styled.div`
    z-index: 300;
    width: 100vw;
    left: 0;
    top: 0;
    box-sizing: border-box;
    background-color: #ffffff;
    border-top: 1px solid rgba(0, 27, 55, 0.1);
`;

const Footer = styled.div`
    padding: 12px 8px;
    max-width: 960px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    margin-bottom: 100px;
    color: #9da2aa;

    @media (max-width: 767px) {
        flex-direction: column;
    }
`;

const FooterItem = styled.div`
    display: flex;
    gap: 32px;
    align-items: flex-start;

    @media (max-width: 767px) {
        margin-top: 8px;
        gap: 8px;
    }
`;

const FooterColumn = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
`;

const Copyright = styled.div`
    font-size: 14px;
`;

const A = styled.a`
    text-decoration: none;
    color: #9da2aa;
`;
const LinkA = styled(Link)`
    text-decoration: none;
    color: #9da2aa;
`;

const Lang = styled.div`
    display: flex;
    gap: 4px;
    cursor: pointer;
    align-items: center;
`;

const Logos = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

const OpizeIconImg = styled.img`
    height: 48px;
    width: 48px;
`;

const OpizeImg = styled.img`
    height: 32px;
    width: 70px;
`;

export default function DashboardConnect(props) {
    const { t, i18n } = useTranslation("translation");

    const changeLanguage = () => {
        const lang = i18n.language === "ko-KR" ? "en-US" : "ko-KR";
        localStorage.setItem("lang", lang);
        i18n.changeLanguage(lang);
    };

    return (
        <footer>
            <FooterDivver>
                <Footer>
                    <FooterColumn>
                        <Logos>
                            <a
                                href="https://old.opize.me"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <OpizeIconImg src={OpizeIcon} alt="logo" />
                            </a>
                            <a
                                href="https://old.opize.me"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <OpizeImg src={Opize} alt="logo" />
                            </a>
                        </Logos>
                        <Copyright>
                            Copyright @2021 Opize.me
                            <br />
                            오피즈(박현우) 836-10-01810
                            <br />
                            충남 서산시 동서2로 15, 101동 301호 (석림동,
                            석림한성필아우스)
                            <br />
                            대표번호: 010-4099-3071
                        </Copyright>
                    </FooterColumn>
                    <FooterItem>
                        <A
                            href={t("privacy_policy")}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Terms & Privacy
                        </A>
                        <LinkA to="/guide">{t("header_menu_guide")}</LinkA>
                        <LinkA to="/plan">{t("header_menu_plan")}</LinkA>
                        <A
                            href={t("header_menu_question_link")}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {t("header_menu_question")}
                        </A>
                        <Lang onClick={changeLanguage}>
                            <Globe size={20} />
                            {i18n.language === "ko-KR" ? "KOR" : "ENG"}
                        </Lang>
                    </FooterItem>
                </Footer>
            </FooterDivver>
        </footer>
    );
}
