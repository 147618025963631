import React from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { useNavigate } from 'react-router';
import axios from 'axios';
import { toast } from 'react-toastify';


import { ReactComponent as Icon } from '../../assets/calendar-plus.svg';

export default function DashboardCalendar(props) {
    const { t } = useTranslation('translation')
    const navigate = useNavigate()

    const changeCalendar = async (_, action) => {
        try {
            if (action.action === "remove-value") {
                await axios.delete(`${process.env.REACT_APP_API_SERVER}/calendar?calendarId=${action.removedValue.value}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                })
            } else if (action.action === "select-option") {
                await axios.post(`${process.env.REACT_APP_API_SERVER}/calendar`, {
                    calendarId: action.option.value
                }, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                })
            } else if (action.action === "clear") {
                for (let calendar of action.removedValues) {
                    await axios.delete(`${process.env.REACT_APP_API_SERVER}/calendar?calendarId=${calendar.value}`,{
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`
                        }
                    })
                }
            }
        } catch (err) {
            if (err.response) {
                if (err.response.status === 401) {
                    localStorage.removeItem("token")
                    toast.error(t("toast_err_token_expired"));
                    navigate('/');
                } else {
                    toast.error(t("toast_err_something_wrong"));
                    console.error(err)
                }
            } else {
                console.error(err)
                toast.error(t("toast_err_can_not_connect_server"))
            }
        }
    }

    let options = props.userInfo.calendars.map(e => {
        let title = e.id === props.userInfo.user.email ? t("dashboard_page_calendar_select_primary") : e.summary
        if (e.accessRole === "reader") {
            title = `${title} (${t("dashboard_page_calendar_select_readonly")})`
        }

        if (props.userInfo.user.plan === "free" && !e.primary) {
            return {
                value: e.id,
                label: title,
                isDisabled: true
            }
        } else if (props.userInfo.user.plan !== "pro" && e.accessRole === "reader") {
            return {
                value: e.id,
                label: title,
                isDisabled: true
            }
        }
        return {
            value: e.id,
            label: title
        }
    })

    let defaultValue = props.userInfo.calendars.map(e => {
        let title = e.id === props.userInfo.user.email ? t("dashboard_page_calendar_select_primary") : e.summary
        if (e.accessRole === "reader") {
            title = `${title} (${t("dashboard_page_calendar_select_readonly")})`
        }

        if (e.isSelected) return {
            value: e.id,
            label: title
        }
        return null
    })
    defaultValue = defaultValue.filter(Boolean)

    return (
        <div className="card">
            <div className="icon">
                <Icon />
            </div>
            <h3>
                {t("dashboard_page_calendar_title_1")}
            </h3>
            <div className="calendar-select">
                <Select
                    isMulti
                    options={options}
                    defaultValue={defaultValue}
                    onChange={changeCalendar}
                    label=""
                    isOptionDisabled={(option) => option.isDisabled}
                />
            </div>
            {/* <a href="" target="_blank" rel="noopener noreferrer">{t("dashboard_page_calendar_desc_1")}</a> */}
        </div>
    )
}