import React, { useState, useEffect, useRef } from 'react';
import {  } from 'react-router';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useForm } from "react-hook-form";
import { v4 as uuidv4 } from 'uuid';

import '../../css/dashboard.scss';

import Header from '../../Components/admin/header';
import CodeBlock from '../../Components/admin/codeBlock';
import {InputDiv, InputRow, InputRowText, InputRowInput, InputSubmitDiv, InputSubmit} from "../../Components/admin/input"

const CreateCoupon = (props) => {
    const DBprops = useRef({
        id: {
            value: "uuid",
            placeholder: "쿠폰 코드"
        },
        name: {
            placeholder: "쿠폰 이름"
        }, 
        months: {
            placeholder: "플랜 기간 (0: 무제한)"
        },
        plan: {
            placeholder: "basic, pro"
        },
        quota: {
            value: "1"
        }
    })
    const { register, handleSubmit, setValue } = useForm();
    const [btnStatus, setBtnStatus] = useState({text: "생성", color: "#649DFF"})

    useEffect(() => {
        for (let i of Object.keys(DBprops.current)) {
            setValue(i, DBprops.current[i].value === "uuid" ? uuidv4() : DBprops.current[i].value || "")
        }
    }, [setValue])

    const Edit = async (data) => {
        try {
            await axios.post(`${process.env.REACT_APP_API_SERVER}/admin/coupon`, data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            setBtnStatus({text: "생성 완료", color: "#0FBE9F"})
            setTimeout(() => {
                setBtnStatus({text: "생성", color: "#649DFF"})
            }, 2000)

            props.setUserSearch(data.id)
            props.fetchCoupon(data.id)

            for (let i of Object.keys(DBprops.current)) {
                setValue(i, DBprops.current[i].value === "uuid" ? uuidv4() : DBprops.current[i].value || "")
            }


        } catch (err) {
            if (err.response) {
                console.error(err)
                toast.warn("문제가 발생했습니다.")
            } else {
                console.error(err)
                toast.warn("문제가 발생했습니다.")
            }
        }
    }

    return (
        <InputDiv onSubmit={handleSubmit(Edit)}>
            {
                Object.keys(DBprops.current).map(e => {
                    return (
                        <InputRow key={e}>
                            <InputRowText>{e}</InputRowText>
                            <InputRowInput {...register(e)} placeholder={DBprops.current[e].placeholder || ""} />
                        </InputRow>
                    )
                })
            }
            <InputSubmitDiv>
                <InputSubmit type={"button"} value={"랜덤 아이디"} color={"#649DFF"} onClick={() => {setValue('id', uuidv4())}}/>
                <InputSubmit type={"submit"} value={btnStatus.text} color={btnStatus.color}/>
            </InputSubmitDiv>
        </InputDiv>
    )
}

export default function Coupon(props) {
    const [userSearch, setUserSearch] = useState("")
    const [info, setInfo] = useState({})

    const fetchCoupon = async (couponId) => {
        try {
            if (userSearch) {
                const res = await axios.get(`${process.env.REACT_APP_API_SERVER}/admin/coupon/${couponId}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                })
                setInfo(res.data.data)
            }
        } catch (err) {
            if (err.response) {
                if (err.response.status === 404) {
                    setInfo({error: "쿠폰을 찾을 수 없습니다."})
                    toast.warn("유저를 찾을 수 없습니다.")
                } else {
                    console.error(err)
                    toast.warn("문제가 발생했습니다.")
                }
            } else {
                console.error(err)
                toast.warn("문제가 발생했습니다.")
            }
        }
    }

    return (
        <>
            <div>
                <Header title="쿠폰" />
                <CodeBlock placeholder="쿠폰 코드" value={userSearch} onChange={(e) => setUserSearch(e.target.value)} buttonText="조회" run={() => fetchCoupon(userSearch)}>
                    {JSON.stringify(info, null, 2)}
                </CodeBlock>
                <CreateCoupon fetchCoupon={fetchCoupon} setUserSearch={setUserSearch} />
            </div>
        </>
    )
}