import React from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { ReactComponent as Icon } from '../../assets/planet.svg';

export default function DashboardPayment(props) {
    const { t } = useTranslation('translation')
    const dateTimeString = (date) => {
        return dayjs(date).format("YYYY.MM.DD HH:mm:ss")
    }

    return (
        <div className="card">
            <div className="icon">
                <Icon />
            </div>
            <h3>{t("dashboard_page_payment_title_1")}</h3>
            <p>{t("dashboard_page_payment_desc_1")}: {dateTimeString(props.userInfo.payments.lastPaymentTime)}</p>
            {props.userInfo.payments.isUnlimited ? <></> : <p>{t("dashboard_page_payment_desc_2")}: {dateTimeString(props.userInfo.payments.nextPaymentTime)}</p>}
        </div>
    )
}

