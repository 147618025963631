import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';

import '../../css/dashboard.scss';

import User from "./user"
import Statistics from "./statistics"
import Notice from "./notice"
import Coupon from "./coupon"


export default function Dashboard(props) {
    const navigate = useNavigate()
    useEffect(() => {
        if(!props.userInfo.user.isAdmin) {
            navigate("/dashboard")
        }
    }, [props.userInfo, navigate])

    return (
        <>
            <div id="dashboard-page">
                <h2>관리자 페이지</h2>
                <User />
                <Statistics />
                <Notice notice={props.notice} />
                <Coupon />
            </div>
        </>
    )
}