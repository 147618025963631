import React, { useState, useRef, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import GoogleLogin from "react-google-login";
import axios from "axios";
import { toast } from "react-toastify";
import jwt_decode from "jwt-decode";
import { useNavigate, Routes, Route } from "react-router-dom";
// import { styled } from 'styled-component';

import "../css/model.scss";
import { ReactComponent as GoogleLogo } from "../assets/g_logo.svg";
import { ReactComponent as NotionLogo } from "../assets/notion_logo.svg";
import { ReactComponent as CheckIcon } from "../assets/check-circle.svg";

const GoogleLoginStep = (props) => {
    const { t } = useTranslation("translation");
    const navigate = useNavigate();

    const onLoginGoogle = async (result) => {
        // 현재 도메인 호스트를 가져와줘
        const currentHost = window.location.host;

        try {
            let res;

            if (currentHost === "old-calendar2notion.opize.me") {
                res = await axios.post(
                    `${process.env.REACT_APP_API_SERVER}/connect/google`,
                    {
                        code: result.code,
                        version: "old",
                    }
                );
            } else {
                res = await axios.post(
                    `${process.env.REACT_APP_API_SERVER}/connect/google`,
                    {
                        code: result.code,
                    }
                );
            }

            if (res.data.code === "please_use_new_calendar2notion") {
                toast.info("새로운 Calendar2notion을 이용해주세요!");
                return;
            }

            localStorage.setItem("token", res.data.token);
            switch (res.data.code) {
                case "login_success":
                    navigate("/dashboard");
                    break;

                case "go_notion_set":
                    navigate("/connect/notion");
                    break;

                case "go_create_database":
                    navigate("/connect/database");
                    break;

                case "go_finish":
                    navigate("/connect/finish");
                    break;

                default:
                    navigate("/");
                    break;
            }
        } catch (err) {
            if (err.response) {
                if (
                    err.response.data.code === "need_google_calendar_permission"
                ) {
                    toast.info(t("connect_1_toast_1"));
                }
            } else {
                toast.error(t("toast_err_can_not_connect_server"));
            }
        }
    };

    const GoogleLoginBtn = (props) => {
        return (
            <GoogleLogin
                clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                render={(props) => (
                    <div className="btn" onClick={props.onClick}>
                        <GoogleLogo />
                        <div>{t("connect_1_google_btn")}</div>
                    </div>
                )}
                onSuccess={(result) => onLoginGoogle(result)}
                onFailure={(result) => console.log(result)}
                cookiePolicy={"single_host_origin"}
                redirectUri="/connect"
                scope={"profile email https://www.googleapis.com/auth/calendar"}
                accessType={"offline"}
                approvalPrompt={"force"}
                prompt={"consent"}
                responseType={"code"}
            />
        );
    };

    return (
        <>
            <div className="title">
                {t("connect_title_1")}
                <br />
                <span className="underscore">{t("connect_title_2")}</span>
            </div>
            <div className="subtitle">{t("connect_1_subtitle")}</div>
            <div className="desc">
                {t("connect_1_desc_1")}
                <br />
                {t("connect_1_desc_2")}
            </div>
            <GoogleLoginBtn />
            <div className="btn-desc">
                {t("connect_1_google_btn_desc_1")}
                <a href={t("privacy_policy")}>
                    {t("connect_1_google_btn_desc_2")}
                </a>
                {t("connect_1_google_btn_desc_3")}
                <br />
                <a href={t("tos")}>{t("connect_1_google_btn_desc_4")}</a>
                {t("connect_1_google_btn_desc_5")}
            </div>
        </>
    );
};

const NotionLoginStep = (props) => {
    const { t } = useTranslation("translation");
    const navigate = useNavigate();

    const userInfo = jwt_decode(localStorage.getItem("token"));
    const notion_auth_url = `https://api.notion.com/v1/oauth/authorize?client_id=${process.env.REACT_APP_NOTION_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_NOTION_CALLBACK}&response_type=code&owner=user`;

    const anotherAccount = () => {
        localStorage.clear();
        navigate("/connect");
    };

    return (
        <>
            <div className="title">
                {t("connect_title_1")}
                <br />
                <span className="underscore">{t("connect_title_2")}</span>
            </div>
            <div className="subtitle">{t("connect_2_subtitle")}</div>
            <div className="desc">{t("connect_2_desc_1")}</div>
            <a className="btn" href={notion_auth_url}>
                <NotionLogo />
                <div>{t("connect_2_notion_btn")}</div>
            </a>
            <div className="btn-desc">
                {t("connect_2_notion_btn_desc_1", { email: userInfo.email })}
                <br />
                <span className="link" onClick={anotherAccount}>
                    {t("connect_2_notion_btn_desc_2")}
                </span>
            </div>
        </>
    );
};

const DatabaseStep = (props) => {
    const { t } = useTranslation("translation");
    const navigate = useNavigate();
    const userInfo = jwt_decode(localStorage.getItem("token"));

    const [inputValue, setInputValue] = useState("");
    const [isInputAble, setInputAble] = useState(false);

    const continueOtherAccount = () => {
        localStorage.clear();
        navigate("/connect");
    };

    const onInputChange = (value) => {
        setInputValue(value);
        let url_uuid = value.split("/")[value.split("/").length - 1];
        url_uuid = url_uuid.split("-")[url_uuid.split("-").length - 1];

        if (url_uuid.split("=")[1] && url_uuid.split("=")[1]?.length === 32) {
            setInputAble(true);
        }
    };

    const onSetNotionDatabase = async () => {
        if (!isInputAble) return;
        setInputAble(false);

        let url_uuid = inputValue.split("/")[inputValue.split("/").length - 1];
        url_uuid = url_uuid
            .split("-")
            [url_uuid.split("-").length - 1].split("?")[0];

        try {
            const res = await axios.post(
                `${process.env.REACT_APP_API_SERVER}/connect/database`,
                {
                    databaseId: url_uuid,
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "token"
                        )}`,
                    },
                }
            );
            localStorage.setItem("token", res.data.token);
            navigate("/connect/finish");
        } catch (err) {
            console.error(err);
            if (err.response) {
                if (err.response.data.code === "go_notion_set") {
                    console.log(err.response.data);
                    localStorage.removeItem("token");
                    navigate("/");
                    toast.error(t("connect_3_toast_5"));
                    return;
                }

                if (err.response.data.code === "wrong_props") {
                    const errorProps = err.response.data.errorProps;
                    for (let prop in errorProps) {
                        toast.warn(
                            t("connect_3_toast_6", {
                                prop,
                                propType: t(
                                    `connect_3_type_${errorProps[prop]}`
                                ),
                            })
                        );
                    }
                }

                if (err.response.data.code === "database_not_found") {
                    toast.warn(t("connect_3_toast_4"));
                }
            }
            setInputAble(true);
        }
    };

    return (
        <>
            <div className="title">
                {t("connect_title_1")}
                <br />
                <span className="underscore">{t("connect_title_2")}</span>
            </div>
            <div className="subtitle">{t("connect_3_subtitle")}</div>
            <div className="desc">
                {t("connect_3_desc_1")}
                <br />
                {t("connect_3_desc_2")}
            </div>
            <a
                className="btn-link"
                href={t("connect_3_guide_1_link")}
                target={"_blank"}
                rel="noreferrer"
            >
                {t("connect_3_guide_1")}
            </a>
            <a
                className="btn-link"
                href={t("connect_3_guide_2_link")}
                target={"_blank"}
                rel="noreferrer"
            >
                {t("connect_3_guide_2")}
            </a>

            <div className="input">
                <input
                    type="text"
                    placeholder="https://notion.so/xxxxxxxx"
                    value={inputValue}
                    onChange={(e) => onInputChange(e.target.value)}
                />
                <div
                    className={isInputAble ? "active" : "disabled"}
                    onClick={() => onSetNotionDatabase()}
                >
                    <CheckIcon />
                </div>
            </div>

            <div className="btn-desc">
                {t("connect_3_btn_desc_1", { email: userInfo.email })}
                <br />
                <span className="link" onClick={continueOtherAccount}>
                    {t("connect_3_btn_desc_2")}
                </span>
            </div>
        </>
    );
};

const FinishStep = (props) => {
    const { t } = useTranslation("translation");
    const navigate = useNavigate();

    return (
        <>
            <div className="title">
                {t("connect_title_1")}
                <br />
                <span className="underscore">{t("connect_title_2")}</span>
            </div>
            <div className="subtitle">{t("connect_4_subtitle")}</div>
            <div className="desc">
                {t("connect_4_desc_1")}
                <br />
                {t("connect_4_desc_2")}
                <br />
                {t("connect_4_desc_3")}
            </div>
            <a
                target="_blank"
                rel="noopener noreferrer"
                href={t("connect_4_btn_1_link")}
                className="btn btn-1"
            >
                <div>{t("connect_4_btn_1")}</div>
            </a>
            <div className="btn btn-2" onClick={navigate("/dashboard")}>
                <div>{t("connect_4_btn_2")}</div>
            </div>
            <div className="btn-desc">{t("connect_4_btn_desc_1")}</div>
        </>
    );
};

function Connect(props) {
    const navigate = useNavigate();
    const modalEl = useRef();

    useEffect(() => {
        navigate("/connect");
    }, [navigate]);

    const handleClickOutside = useCallback(
        ({ target }) => {
            if (!modalEl.current.contains(target)) {
                navigate("/");
            }
        },
        [navigate]
    );

    const tokenCheck = useCallback(() => {
        let userInfo;
        if (localStorage.getItem("token"))
            userInfo = jwt_decode(localStorage.getItem("token"));

        switch (userInfo.status) {
            case "google_set":
                navigate("/connect/notion");
                break;
            case "notion_set":
                navigate("/connect/database");
                break;
            case "database_created":
                navigate("/connect/finish");
                break;
            case "finish":
                navigate("/dashboard");
                break;
            default:
                navigate("/");
                break;
        }
    }, [navigate]);

    useEffect(() => {
        if (localStorage.getItem("token")) {
            tokenCheck();
        }
    }, [tokenCheck]);

    useEffect(() => {
        window.addEventListener("click", handleClickOutside);
        return () => {
            window.removeEventListener("click", handleClickOutside);
        };
    }, [handleClickOutside]);

    useEffect(() => {
        // 노션 토큰
        (async () => {
            if (props.notionCode) {
                const notion_api_res = await axios.post(
                    `${process.env.REACT_APP_API_SERVER}/connect/notion`,
                    {
                        code: props.notionCode,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem(
                                "token"
                            )}`,
                        },
                    }
                );
                props.setNotionCode("");
                localStorage.setItem("token", notion_api_res.data.token);
                navigate("/connect/database");
            }
        })();
    }, [navigate]);

    return (
        <>
            <div className="modal-divver">
                <div id="modal" ref={modalEl}>
                    <Routes>
                        <Route path="/" element={<GoogleLoginStep />} />
                        <Route path="/notion" element={<NotionLoginStep />} />
                        <Route path="/database" element={<DatabaseStep />} />
                        <Route path="/finish" element={<FinishStep />} />
                    </Routes>
                </div>
            </div>
        </>
    );
}

export default Connect;
