import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';

import timezoneList from '../../data/timezones';

import { ReactComponent as Icon } from '../../assets/globe.svg';

export default function DashboardTimezone(props) {
    const { t } = useTranslation('translation')
    const [userTimezone, setUserTimeZone] = useState(props.userInfo.user.timezone)
    const navigate = useNavigate()

    const changeTimeZone = async (newTimeZone) => {
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_SERVER}/user/timezone`, {
                timezone: newTimeZone.value
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })

            if (res.data.code === "is_working") {
                toast.error(t("toast_err_working"))
                return
            }
            
            setUserTimeZone(newTimeZone.value)

        } catch (err) {
            if (err.response) {
                if (err.response.status === 401) {
                    localStorage.removeItem("token")
                    toast.error(t("toast_err_token_expired"));
                    navigate('/');
                } else {
                    toast.error(t("toast_err_something_wrong"));
                    console.error(err)
                }
            } else {
                console.error(err)
                toast.error(t("toast_err_can_not_connect_server"))
            }
        }
    }

    const timezoneIndex = timezoneList.map(e => e.value)

    let initUserTimeZone = timezoneList[timezoneIndex.indexOf(userTimezone)]

    return (
        <div className="card">
            <div className="icon">
                <Icon />
            </div>
            <h3>
                {t("dashboard_page_timezone_title_1")}
            </h3>
            <div className="timezone-select">
                <Select
                    options={timezoneList}
                    defaultValue={initUserTimeZone}
                    onChange={e => changeTimeZone(e)}
                    label=""
                />
            </div>
            {/* <a href="" target="_blank" rel="noopener noreferrer">{t("dashboard_page_timezone_desc_1")}</a> */}
        </div>
    )
}