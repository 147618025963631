import React from "react";
import { useTranslation } from "react-i18next";

import "../../css/plan.scss";

import { ReactComponent as CheckIcon } from "../../assets/check.svg";
import { ReactComponent as XIcon } from "../../assets/x.svg";
import { toast } from "react-toastify";

export default function PlanPage(props) {
    const { t } = useTranslation("translation");
    const plan = props.userInfo?.user.plan;

    return (
        <>
            <div id="dashboard-page">
                <h2>{t("dashboard_menu_2")}</h2>
                <p className="plan-desc">{t("dashboard_plan_price_desc")}</p>
                <div className="plans">
                    <div className="plan">
                        <h3>Free</h3>
                        <p className="price-month">
                            {t("dashboard_plan_free_price_month")}
                            <span className="price-month">
                                /{t("dashboard_plan_price_unit_month")}
                            </span>
                        </p>
                        <div className="price-year">
                            {t("dashboard_plan_free_price_year")}/
                            {t("dashboard_plan_price_unit_year")}
                        </div>
                        <div className="desc">
                            {t("dashboard_plan_free_desc")}
                        </div>
                        <div className="options">
                            <div className="option option-selected">
                                <CheckIcon />{" "}
                                {t("dashboard_plan_free_option_1")}
                            </div>
                            <div className="option">
                                <XIcon /> {t("dashboard_plan_free_option_2")}
                            </div>
                            <div className="option">
                                <XIcon /> {t("dashboard_plan_free_option_3")}
                            </div>
                        </div>
                        {props.showBtn ? (
                            <div
                                onClick={() => {
                                    toast.info(
                                        "아래 가이드 페이지를 참고하세요"
                                    );
                                }}
                                className={
                                    plan === "free" ? "btn btn-selected" : "btn"
                                }
                            >
                                {plan === "free"
                                    ? t("dashboard_plan_free_btn_selected")
                                    : t("dashboard_plan_free_btn_not_selected")}
                            </div>
                        ) : (
                            ""
                        )}
                    </div>

                    <div className="plan plan-black">
                        <h3>Pro</h3>
                        <p className="price-month">
                            {t("dashboard_plan_basic_price_month")}
                            <span className="price-month">
                                /{t("dashboard_plan_price_unit_month")}
                            </span>
                        </p>
                        <div className="price-year">
                            {t("dashboard_plan_basic_price_year")}/
                            {t("dashboard_plan_price_unit_year")}
                        </div>
                        <div className="desc">
                            {t("dashboard_plan_basic_desc")}
                        </div>
                        <div className="options">
                            <div className="option option-selected">
                                <CheckIcon />{" "}
                                {t("dashboard_plan_basic_option_1")}
                            </div>
                            <div className="option option-selected">
                                <CheckIcon />{" "}
                                {t("dashboard_plan_basic_option_2")}
                            </div>
                            <div className="option option-selected">
                                <CheckIcon />{" "}
                                {t("dashboard_plan_basic_option_3")}
                            </div>
                        </div>
                        {props.showBtn ? (
                            <div
                                onClick={() => {
                                    toast.info(
                                        "아래 가이드 페이지를 참고하세요"
                                    );
                                }}
                                className={
                                    plan === "pro" ? "btn btn-selected" : "btn"
                                }
                            >
                                {plan === "pro"
                                    ? t("dashboard_plan_basic_btn_selected")
                                    : plan === "basic"
                                    ? t(
                                          "dashboard_plan_basic_btn_not_selected_1"
                                      )
                                    : t(
                                          "dashboard_plan_basic_btn_not_selected_2"
                                      )}
                            </div>
                        ) : (
                            ""
                        )}
                    </div>

                    <div className="plan">
                        <h3>Pro +</h3>
                        <p className="price-month">
                            {t("dashboard_plan_pro_price_month")}
                            <span className="price-month">
                                /{t("dashboard_plan_price_unit_month")}
                            </span>
                        </p>
                        <div className="price-year">
                            {t("dashboard_plan_pro_price_year")}/
                            {t("dashboard_plan_price_unit_year")}
                        </div>
                        <div className="desc">
                            {t("dashboard_plan_pro_desc")}
                        </div>
                        <div className="options">
                            <div className="option option-selected">
                                <CheckIcon /> {t("dashboard_plan_pro_option_1")}
                            </div>
                            <div className="option option-selected">
                                <CheckIcon /> {t("dashboard_plan_pro_option_2")}
                            </div>
                            <div className="option option-selected">
                                <CheckIcon /> {t("dashboard_plan_pro_option_3")}
                            </div>
                            <div className="option option-selected">
                                <CheckIcon /> {t("dashboard_plan_pro_option_4")}
                            </div>
                        </div>
                        {props.showBtn ? (
                            <div
                                onClick={() => {
                                    toast.info(
                                        "아래 가이드 페이지를 참고하세요"
                                    );
                                }}
                                className={
                                    plan === "basic"
                                        ? "btn btn-selected"
                                        : "btn"
                                }
                            >
                                {plan === "basic"
                                    ? t("dashboard_plan_pro_btn_selected")
                                    : t("dashboard_plan_pro_btn_not_selected")}
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
                {props.showBtn ? (
                    <iframe
                        src={t("dashboard_plan_guide_link")}
                        title="plan guide"
                    ></iframe>
                ) : (
                    ""
                )}
            </div>
        </>
    );
}
