import React, { useState, useEffect, useRef } from 'react';
import {  } from 'react-router';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useForm } from "react-hook-form";

import '../../css/dashboard.scss';

import Header from '../../Components/admin/header';
import CodeBlock from '../../Components/admin/codeBlock';
import {InputDiv, InputRow, InputRowText, InputRowInput, InputSubmitDiv, InputSubmit} from "../../Components/admin/input"

const EditUser = (props) => {
   
    const { register, handleSubmit, setValue} = useForm();
    const [btnStatus, setBtnStatus] = useState({text: "업데이트", color: "#649DFF"})
    const [deleteBtnStatus, setDeleteBtnStatus] = useState({text: "계정 삭제", color: "#FF6464"})

    useEffect(() => {
        const DBprops = ['name', 'imageUrl', 'lastSyncStatus', 'status', 'isConnected', 'userTimeZone', 'isWork', 'isAdmin', 'notionDatabaseId']
        if (props.userInfo?.user && !props.userInfo?.user?.error) {
            for (let i of DBprops) {
                setValue(i, String(props.userInfo.user[i] !== null ? props.userInfo.user[i] : ""))
            }
        } else {
            for (let i of DBprops) {
                setValue(i, "")
            }
        }
        
    }, [props.userInfo, setValue])

    const Edit = async (data) => {
        try {
            await axios.patch(`${process.env.REACT_APP_API_SERVER}/admin/user/${props.userInfo?.user?.id}`, data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            setBtnStatus({text: "업데이트 완료", color: "#0FBE9F"})
            setTimeout(() => {
                setBtnStatus({text: "업데이트", color: "#649DFF"})
            }, 2000)
            props.fetchUser()
        } catch (err) {
            if (err.response) {
                if (err.response.status === 404) {
                    toast.warn("유저를 찾을 수 없습니다.")
                } else {
                    console.error(err)
                    toast.warn("문제가 발생했습니다.")
                }
            } else {
                console.error(err)
                toast.warn("문제가 발생했습니다.")
            }
        }
    }

    const deleteUser = async () => {
        if (props.userInfo?.user && !props.userInfo?.user?.error) {
            if(window.confirm(`정말로 ${props.userInfo.user.name} 유저를 삭제하시겠습니까?`)) {
                try {
                    await axios.delete(`${process.env.REACT_APP_API_SERVER}/admin/user/${props.userInfo?.user?.id}`, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`
                        }
                    })
                    setDeleteBtnStatus({text: "삭제 완료", color: "#0FBE9F"})
                    setTimeout(() => {
                        setDeleteBtnStatus({text: "계정 삭제", color: "#FF6464"})
                    }, 2000)
                    props.fetchUser()
                } catch (err) {
                    if (err.response) {
                        if (err.response.status === 404) {
                            toast.warn("유저를 찾을 수 없습니다.")
                        } else {
                            console.error(err)
                            toast.warn("문제가 발생했습니다.")
                        }
                    } else {
                        console.error(err)
                        toast.warn("문제가 발생했습니다.")
                    }
                }
            }
        }
    }

    return (
        <InputDiv onSubmit={handleSubmit(Edit)}>
            {
                ['name', 'imageUrl', 'lastSyncStatus', 'status', 'isConnected', 'userTimeZone', 'isWork', 'isAdmin', 'notionDatabaseId'].map(e => {
                    return (
                        <InputRow key={e}>
                            <InputRowText>{e}</InputRowText>
                            <InputRowInput {...register(e)} />
                        </InputRow>
                    )
                })
            }
            <InputSubmitDiv>
                <InputSubmit type={"button"} color={deleteBtnStatus.color} value={deleteBtnStatus.text} readOnly onClick={deleteUser}/>
                <InputSubmit type={"submit"} value={btnStatus.text} color={btnStatus.color}/>
            </InputSubmitDiv>
        </InputDiv>
    )
}

const EditUserPlan = (props) => {
    const DBprops = useRef({
        memo: {
            placeholder: "(plan)플랜 1년",
            value: 'pro 플랜 1년'
        },
        plan: {
            placeholder: "free, pro, basic(ProPlus)",
            value: 'pro'
        }, 
        months: {
            placeholder: "+12, 12",
        },
        price: {
            placeholder: "pro: 12000KRW, ProPlus: 18000KRW",
            value: "12000"
        },
        priceKind: {
            value: "KRW"
        },
        paymentKind: {
            placeholder: "카카오페이, 계좌이체"
        }
    })
    const { register, handleSubmit, setValue } = useForm();
    const [btnStatus, setBtnStatus] = useState({text: "업데이트", color: "#649DFF"})

    useEffect(() => {
        for (let i of Object.keys(DBprops.current)) {
            setValue(i, DBprops.current[i].value || "")
        }
    }, [props.userInfo, setValue])

    const Edit = async (data) => {
        try {
            await axios.post(`${process.env.REACT_APP_API_SERVER}/admin/user/${props.userInfo?.user?.id}/plan`, data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            setBtnStatus({text: "업데이트 완료", color: "#0FBE9F"})
            setTimeout(() => {
                setBtnStatus({text: "업데이트", color: "#649DFF"})
            }, 2000)
            props.fetchUser()
        } catch (err) {
            if (err.response) {
                if (err.response.status === 404) {
                    toast.warn("유저를 찾을 수 없습니다.")
                } else {
                    console.error(err)
                    toast.warn("문제가 발생했습니다.")
                }
            } else {
                console.error(err)
                toast.warn("문제가 발생했습니다.")
            }
        }
    }

    return (
        <InputDiv onSubmit={handleSubmit(Edit)}>
            {
                Object.keys(DBprops.current).map(e => {
                    return (
                        <InputRow key={e}>
                            <InputRowText>{e}</InputRowText>
                            <InputRowInput {...register(e)} placeholder={DBprops.current[e].placeholder || ""} />
                        </InputRow>
                    )
                })
            }
            <InputSubmitDiv>
                <div></div>
                <InputSubmit type={"submit"} value={btnStatus.text} color={btnStatus.color}/>
            </InputSubmitDiv>
        </InputDiv>
    )
}

export default function User(props) {
    const [userSearch, setUserSearch] = useState("")
    const [userInfo, setUserInfo] = useState({})

    const fetchUser = async () => {
        try {
            if (userSearch) {
                let query = userSearch.includes("@") ? "email" : "id"
                const userResponse = await axios.get(`${process.env.REACT_APP_API_SERVER}/admin/user?${query}=${userSearch}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                })
                setUserInfo(userResponse.data.data)
                console.log(userResponse)
            }
        } catch (err) {
            if (err.response) {
                if (err.response.status === 404) {
                    setUserInfo({user: {error: "유저를 찾을 수 없습니다."}})
                    toast.warn("유저를 찾을 수 없습니다.")
                } else {
                    console.error(err)
                    toast.warn("문제가 발생했습니다.")
                }
            } else {
                console.error(err)
                toast.warn("문제가 발생했습니다.")
            }
        }
    }

    return (
        <>
            <div>
                <Header title="유저 관리" />
                <CodeBlock placeholder="유저 아이디 또는 이메일" value={userSearch} onChange={(e) => setUserSearch(e.target.value)} buttonText="조회" run={fetchUser}>
                    {JSON.stringify(userInfo?.user, null, 2)}
                </CodeBlock>
                <EditUser userInfo={userInfo} fetchUser={fetchUser} />
                <EditUserPlan userInfo={userInfo} fetchUser={fetchUser} />
                <CodeBlock readOnly value={"결제 정보 (payment)"}>
                    {JSON.stringify(userInfo?.payment, null, 2)}
                </CodeBlock>
                <CodeBlock readOnly value={"결제 로그 (paymentLog)"}>
                    {JSON.stringify(userInfo?.paymentLog, null, 2)}
                </CodeBlock>
                <CodeBlock readOnly value={"쿠폰 로그 (couponLog)"}>
                    {JSON.stringify(userInfo?.couponLog, null, 2)}
                </CodeBlock>
                <CodeBlock readOnly value={"캘린더 (calendars)"}>
                    {JSON.stringify(userInfo?.calendars, null, 2)}
                </CodeBlock>
                <CodeBlock readOnly value={"데이터베이스 속성 (databaseProp)"}>
                    {JSON.stringify(userInfo?.databaseProp, null, 2)}
                </CodeBlock>
                <CodeBlock readOnly value={"기타"}>
                    {JSON.stringify({
                        eventCount: userInfo?.eventCount,
                        token: userInfo?.token
                    }, null, 2)}
                </CodeBlock>
            </div>
        </>
    )
}