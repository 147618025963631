import dayjs from "dayjs";
import styled from "styled-components";

const getStatusColor = (status) => {
    switch (status) {
        case "good":
            return {
                background: "#EAF7EE",
                color: "#3FBE61"
            }
        case "finish":
            return {
                background: "#E6EFFA",
                color: "#0268DB"
            }
        case "warn":
            return {
                background: "#FEF7EA",
                color: "#EE9500"
            }
        case "error":
            return {
                background: "#FCEDE9",
                color: "#ED4E2C"
            }
        default:
            return {
                background: "#EAF7EE",
                color: "#3FBE61"
            }
    }
}

const StatusDiv = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding: 12px 16px;
    border-radius: 4px;
    background-color: ${props => getStatusColor(props.status).background};
    display: flex;
    flex-direction: column;
    gap: 4px;
`

const StatusInfo = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const StatusInfoGroup = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
`

const StatusDot = styled.div`
    width: 16px;
    height: 16px;
    border-radius: 8px;
    background-color: ${props => getStatusColor(props.status).color};
`

const StatusText = styled.div`
    color: ${props => getStatusColor(props.status).color};
`

const Title = styled.div`

`

const StatusData = styled.pre`
    width: 100%;
    white-space: pre-wrap;
    word-break: break-all;
    font-size: 14px;
`

const StatusBottom = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    opacity: 0.4;
`

const Status = (props) => {
    return (
        <StatusDiv status={props.status}>
            <StatusInfo>
                <StatusInfoGroup>
                    <StatusDot status={props.status} />
                    <StatusText status={props.status}>{props.statusText || "{statusText}"}</StatusText>
                    <Title>{props.title || "{title}"}</Title>
                </StatusInfoGroup>
                <StatusInfoGroup>
                    {props.subtitle || "{subtitle}"}
                </StatusInfoGroup>
            </StatusInfo>
            {props.data && <StatusData>{props.data}</StatusData>}
            <StatusBottom>
                <div>{props.uuid || ""}</div>
                <div>{props.date || dayjs().format("YYYY.MM.DD HH:mm:ss")}</div>
            </StatusBottom>
        </StatusDiv>
    )
}

export default Status