import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Icon } from '../../assets/planet.svg';

export default function DashboardPlan(props) {
    const { t } = useTranslation('translation')
    let planDesc
    let updateCircle
    if (props.userInfo.user.plan === "free") {
        planDesc = t("dashboard_page_plan_desc_1")
        updateCircle = "slow"
    } else if (props.userInfo.user.plan === "basic") {
        planDesc = t("dashboard_page_plan_desc_2")
        updateCircle = "middle"
    } else {
        planDesc = t("dashboard_page_plan_desc_3")
        updateCircle = "high"
    }

    return (
        <div className="card">
            <div className="icon">
                <Icon />
            </div>
            <h3>
                {props.userInfo.user.plan + t("dashboard_page_plan_title_1")}
            </h3>
            <p>{planDesc}</p>
            <p>{t("dashboard_page_plan_rate")} {updateCircle}</p>
            <p>{t("dashboard_page_plan_select_1")} {props.userInfo.user.plan !== "free" ? "👍" : "❌"} | {t("dashboard_page_plan_select_2")} {props.userInfo.user.plan === "pro" ? "👍" : "❌"}</p>
        </div>
    )
}

