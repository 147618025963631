import styled from "styled-components"
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atelierLakesideDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';

const CodeBlockDiv = styled.div`
    border-radius: 4px;
    background-color: #1A183E !important;
    padding: 12px;
    margin-top: 8px;
    box-sizing: border-box;

    pre {
        background-color: #1A183E !important;
        border-radius: 4px;
    }
`

const CodeBlockTitleDiv = styled.div`
    box-sizing: border-box;
    width: 100%;
    color: #ffffff;
    display: flex;
    gap: 8px;
`

const CodeBlockTitleInput = styled.input`
    background-color: #28264E;
    padding: 8px 16px;
    border: 0;
    width: 100%;
    color: #ffffff;

    &:focus {
        outline: none;
    }
`

const CodeBlockTitle = styled.div`
    background-color: #28264E;
    padding: 8px 16px;
    border: 0;
    width: 100%;
    color: #ffffff;

`

const CodeBlockTitleConform = styled.div`
    background-color: #28264E;
    padding: 8px 16px;
    border: 0;
    width: 100px;
    text-align: center;
    cursor: pointer;
    transition: 200ms;
    user-select: none;
    box-sizing: border-box;

    &:hover {
        background-color: #373461;
    }
`

export default function CodeBlock (props) {
    return (
        <CodeBlockDiv>
            <CodeBlockTitleDiv>
                {props.readOnly ? <CodeBlockTitle>{props.value}</CodeBlockTitle> : <CodeBlockTitleInput readOnly={props.readOnly} value={props.value} onChange={props.onChange || (() => {})} type={"text"} placeholder={props.placeholder || "placeholder"}/>}

                {props.readOnly || <CodeBlockTitleConform onClick={props.run || (() => {})}>{props.buttonText}</CodeBlockTitleConform>}
            </CodeBlockTitleDiv>
            <SyntaxHighlighter language="json" style={atelierLakesideDark}>
                {props.children || ""}
            </SyntaxHighlighter>
        </CodeBlockDiv>
    )
}