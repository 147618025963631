import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { toast } from 'react-toastify';

import { ReactComponent as CheckIcon } from '../../assets/check-circle.svg';
import { ReactComponent as Icon } from '../../assets/globe.svg';

export default function DashboardCoupon(props) {
    const { t } = useTranslation('translation')

    const [inputValue, setInputValue] = useState("")
    const [isInputAble, setInputAble] = useState(false)


    const onInputChange = (value) => {
        setInputValue(value)
        if (value.length !== 0) {
            setInputAble(true)
        } else {
            setInputAble(false)
        }
    }

    const goCoupon = async () => {
        if (!isInputAble) return
        setInputAble(false)

        let res;
        try {
            res = await axios.post(`${process.env.REACT_APP_API_SERVER}/user/coupon`, {
                coupon: inputValue
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            if (res.data.code === "coupon_expire") {
                toast.warn(t("dashboard_page_coupon_toast_1"))
            } else if (res.data.code === "coupon_not_used") {
                toast.info(t("dashboard_page_coupon_toast_2"))
            } else if (res.data.code === "coupon_used") {
                window.location.replace("/dashboard?coupon=use")
            }
        } catch (err) {
            setInputAble(true)
            if (err.response) {
                if (err.response.data.code === "coupon_not_found") {
                    toast.error(t("dashboard_page_coupon_toast_4"))
                } else if (err.response.status === 401) {
                    localStorage.clear()
                    toast.error(t("toast_err_token_expired"));
                    props.history.push('/');
                } else {
                    toast.error(t("toast_err_can_not_connect_server"));
                    console.error(err)
                }
            } else {
                console.error(err.data)
                toast.error(t("toast_err_something_wrong"))
            }
        }
    }

    return (
        <div className="card">
            <div className="icon">
                <Icon />
            </div>
            <h3>
                {t("dashboard_page_coupon_title_1")}
            </h3>
            <div className="input">
                <input type="text" placeholder={t("dashboard_page_coupon_input_1")} value={inputValue} onChange={(e) => onInputChange(e.target.value)} />
                <div className={isInputAble ? "active" : "disabled"} onClick={() => goCoupon()}><CheckIcon /></div>
            </div>
        </div>
    )
}