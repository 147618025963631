import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';

import Index from './Screens/index';
import Dashboard from './Screens/dashboard';
import Guide from './Screens/guide';
import Plan from './Screens/plan';
import Introduce  from './Screens/introduce';
import Notion from './Screens/notion';

import Dots from "./Components/dots";
import { toast } from 'react-toastify';

function Reset(props) {
    const navigate = useNavigate()

    useEffect(() => {
        localStorage.clear()
        toast.info("Token Reset")
        navigate("/")
    }, [navigate])

    return (
        <></>
    )
}

function Router() {
    return (
        <>
            <BrowserRouter>
                <Dots />
                <Routes>
                    <Route path="/" element={<Index />} />
                    <Route path="/connect/*" element={<Index />} />
                    <Route path="/dashboard/*" element={<Dashboard />} />
                    <Route path="/guide" element={<Guide />} />
                    <Route path="/plan" element={<Plan />} />
                    <Route path="/introduce" element={<Introduce />} />
                    <Route path="/notion/*" element={<Notion />} />
                    <Route path="/reset" element={<Reset />} /> 
                </Routes>
            </BrowserRouter>
        </>
    )
}

export default Router