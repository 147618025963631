import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify';
import queryString from 'query-string';
import axios from 'axios';
import Channel from "../Components/channelTalk"

import DashboardPage from "./dashboard/dashboard";
import PlanPage from "./plan/index";
import AdminPage from "./admin/index";
import SyncerPage from "./syncer/index";

import Header from "../Components/header";
import Footer from "../Components/footer";
import Loading from "../Components/loading";

import '../css/dashboard.scss';
import { useLocation, useNavigate, Routes, Route } from 'react-router-dom';


export default function Dashboard(props) {
    const { t } = useTranslation('translation')
    const [userInfo, setUserInfo] = useState({})
    const [notice, setNotice] = useState()
    const location = useLocation()
    const navigate = useNavigate()

    if (!localStorage.getItem('token')) {
        navigate('/')
    }

    useEffect(() => {
        // 쿠폰 사용시 알림창
        const query = queryString.parse(location.search);
        if (query.coupon) {
            toast.info(t("dashboard_coupon"))
            navigate('/dashboard');
        }

        // 유저 정보 가져옴
        ;(async () => {
            try {
                const userResponse = await axios.get(`${process.env.REACT_APP_API_SERVER}/user`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                })

                if (userResponse.data.data.user.status !== "finish") {
                    localStorage.removeItem("token")
                    navigate("/")
                    return
                }

                localStorage.setItem('token', userResponse.data.token)
                setUserInfo(userResponse.data.data)

                // 채널톡
                Channel.boot({
                    pluginKey: "7749bc67-021a-45c9-a614-2f3c8f22949a",
                    profile: {
                        "name": userResponse.data.data.user.name,
                        "email": userResponse.data.data.user.email,
                        "created": userResponse.data.data.user.created,
                        "database": userResponse.data.data.notion.databaseId.replaceAll("-", ""),
                        "isConnected": userResponse.data.data.sync.isConnected,
                        "plan": userResponse.data.data.user.plan
                    }
                });
            } catch (err) {
                if (err.response) {
                    console.log(err.response.data.code)
                    if (err.response.status === 401 || err.response.data.code === "user_not_found") {
                        localStorage.removeItem("token")
                        toast.error(t("toast_err_token_expired"));
                        navigate('/');
                    } else if (err.response.data.code === "token_expired") {
                        localStorage.removeItem("token")
                        navigate('/');
                    } else {
                        toast.error(t("toast_err_something_wrong"));
                        console.error(err)
                    }
                } else {
                    console.error(err)
                    toast.error(t("toast_err_can_not_connect_server"))
                }
            }
        })()

        // 공지 정보 가져옴
        ;(async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_SERVER}/landing/notice`)
                setNotice(response.data)
            } catch (e) {
                console.error(e)
                toast.error(t("toast_err_something_wrong"))
            }
        })()
    }, [t, location.search, navigate])

    return (
        <>
            <Header />
            <div id="dashboard">
                <h1>{t("dashboard_title_1")}<span className="underscore">{userInfo.user?.name}</span>{t("dashboard_title_2")}</h1>
                <div className="menus">
                    <div onClick={() => { navigate("/dashboard") }} className={location.pathname === "/dashboard" ? "menu selected" : "menu"} >{t("dashboard_menu_1")}</div>
                    <div onClick={() => { navigate("/dashboard/plan") }} className={location.pathname === "/dashboard/plan" ? "menu selected" : "menu"}>{t("dashboard_menu_2")}</div>
                    {userInfo.user?.isAdmin ? <div onClick={() => { navigate("/dashboard/admin") }} className={location.pathname === "/dashboard/admin" ? "menu selected" : "menu"}>관리자</div> : <></>}
                    {userInfo.user?.isAdmin ? <div onClick={() => { navigate("/dashboard/syncer") }} className={location.pathname === "/dashboard/syncer" ? "menu selected" : "menu"}>동기화봇</div> : <></>}
                </div>
                <div className="pages">
                    {userInfo?.user?.name ? <Routes>
                        <Route path="/" element={<DashboardPage userInfo={userInfo} setUserInfo={setUserInfo} notice={notice} />} />
                        <Route path="/plan" element={<PlanPage userInfo={userInfo} showBtn />} />
                        { userInfo.user?.isAdmin && <Route path="/admin" element={<AdminPage userInfo={userInfo} setUserInfo={setUserInfo} notice={notice} />} />}
                        { userInfo.user?.isAdmin && <Route path="/syncer" element={<SyncerPage userInfo={userInfo} setUserInfo={setUserInfo} />} />}
                    </Routes> : <Loading />}
                </div>
            </div>
            <Footer />
        </>
    )
}