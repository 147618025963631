import React from "react";
import '../css/loading.scss'

const Loading = () => (
    <div className="loading">
        <div className="dot1"></div>
        <div className="dot2"></div>
        <div className="dot3"></div>
    </div>
)

export default Loading