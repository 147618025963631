import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';

import { ReactComponent as CheckIcon } from '../../assets/check-circle.svg';
import { ReactComponent as Icon } from '../../assets/chat-circle-dots.svg';

export default function DashboardReview(props) {
    const { t } = useTranslation('translation')
    const navigate = useNavigate()

    const [inputValue, setInputValue] = useState("")
    const [isInputAble, setInputAble] = useState(false)


    const onInputChange = (value) => {
        setInputValue(value)
        if (value.length !== 0) {
            setInputAble(true)
        } else {
            setInputAble(false)
        }
    }

    const goReview = async () => {
        if (!isInputAble) return
        setInputAble(false)

        try {
            await axios.post(`${process.env.REACT_APP_API_SERVER}/review`, {
                review: inputValue
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            toast.info(t("dashboard_page_review_toast_1"))
            setInputValue("")
            setInputAble(true)
        } catch (err) {
            console.error(err)
            setInputAble(true)
            if (err.response) {
                if (err.response.status === 401) {
                    localStorage.removeItem("token")
                    toast.error(t("toast_err_token_expired"));
                    navigate('/');
                } else {
                    toast.error(t("toast_err_something_wrong"));
                    console.error(err)
                }
            } else {
                console.error(err)
                toast.error(t("toast_err_can_not_connect_server"))
            }
        }
    }

    return (
        <div className="card">
            <div className="icon">
                <Icon />
            </div>
            <h3>
                {t("dashboard_page_review_title_1")}
            </h3>
            <div className="input input-textarea">
                <textarea placeholder={t("dashboard_page_review_desc_1")} value={inputValue} onChange={(e) => onInputChange(e.target.value)}></textarea>
                <div className={isInputAble ? "active" : "disabled"} onClick={() => goReview()}><CheckIcon /></div>
            </div>
        </div>
    )
}