import React, { useState, useEffect } from 'react';
import {  } from 'react-router';
import axios from 'axios';
import { toast } from 'react-toastify';

import '../../css/dashboard.scss';

import Header from '../../Components/admin/header';
import CodeBlock from '../../Components/admin/codeBlock';

export default function Statistics(props) {
    const [statistics, setStatistics] = useState({})

    useEffect(() => {
        (async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_SERVER}/admin/statistics`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                })
                setStatistics(response.data)
                
            } catch (err) {
                if (err.response) {
                    if (err.response.status === 404) {
                        setStatistics({error: "유저를 찾을 수 없습니다."})
                        toast.warn("유저를 찾을 수 없습니다.")
                    } else {
                        console.error(err)
                        toast.warn("문제가 발생했습니다.")
                    }
                } else {
                    console.error(err)
                    toast.warn("문제가 발생했습니다.")
                }
            }
        })()
    }, [])

    return (
        <>
            <div>
                <Header title="통계" />
                <CodeBlock value="통계" readOnly>
                    {JSON.stringify(statistics, null, 2)}
                </CodeBlock>
            </div>
        </>
    )
}