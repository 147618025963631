import React from "react";
import { useLocation } from "react-router-dom";
import '../css/dots.scss'

const Dots = (props) => {
    const location = useLocation()
    return (
        <div className="dots">
            <div id="dot1" className={location.pathname === "/" || location.pathname.startsWith("/connect") ? "dot1" : "dot-move1 dot1"} ></div>
            <div id="dot2" className={location.pathname === "/" || location.pathname.startsWith("/connect") ? "dot2" : "dot-move2 dot2"}></div>
        </div>
    )
}

export default Dots