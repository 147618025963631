import React from 'react';
import { useTranslation } from 'react-i18next'

import DashboardConnect from "./connect";
import DashboardPlan from "./plan";
import DashboardPayment from "./payment";
import DashboardAccount from "./account";
import DashboardTimezone from "./timezone";
import DashboardCoupon from "./coupon";
import DashboardReview from "./review";
import DashboardCalendar from "./calendar";
import DashboardLogout from "./logout";

import '../../css/dashboard.scss';


export default function Dashboard(props) {
    const { t, i18n } = useTranslation('translation')

    return (
        <>
            <div id="dashboard-page">
                <h2>{t("dashboard_menu_1")}</h2>
                {props.notice ? <a className="card notice" target="_blank" rel="noopener noreferrer" href={props.notice.link[i18n.language]}>{props.notice.title[i18n.language]}</a> : <></>}
                <div className="cards">
                    <DashboardConnect userInfo={props.userInfo} />
                    <DashboardPlan userInfo={props.userInfo} />
                    {"payments" in props.userInfo ? <DashboardPayment userInfo={props.userInfo} /> : <></>}
                    <DashboardAccount userInfo={props.userInfo} />
                    <DashboardTimezone userInfo={props.userInfo} />
                    <DashboardCalendar userInfo={props.userInfo} />
                    <DashboardReview userInfo={props.userInfo} />
                    <DashboardCoupon />
                    {/* <DashboardHelp /> */}
                    <DashboardLogout />
                </div>
            </div>
        </>
    )
}