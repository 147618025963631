import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Icon } from '../../assets/link-simple.svg';
import { ReactComponent as NotionLogo } from '../../assets/notion_logo.svg';
import { ReactComponent as GoogleLogo } from '../../assets/g_logo.svg';

export default function DashboardAccount(props) {
    const { t } = useTranslation('translation')

    return (
        <div className="card">
            <div className="icon">
                <Icon />
            </div>
            <h3>
                {t("dashboard_page_account_title_1")}
            </h3>
            <div className="accounts">
                <div className="account">
                    <GoogleLogo />
                    <p>
                        {props.userInfo.user.email}
                    </p>
                </div>

                <div className="account">
                    <NotionLogo />
                    <a href={`https://notion.so/${props.userInfo.notion.databaseId.replaceAll("-", "")}`} target="_blank" rel="noopener noreferrer" >
                        https://notion.so/{props.userInfo.notion.databaseId.replaceAll("-", "").slice(0, 16)}...
                    </a>
                </div>
            </div>
        </div>
    )
}

