import React, { useState } from 'react';
import { useTranslation } from 'react-i18next'
import axios from 'axios';
import { toast } from 'react-toastify';
import dayjs from 'dayjs'

export default function DashboardConnect(props) {
    const { t, i18n } = useTranslation('translation')
    const [isConnected, setConnected] = useState(props.userInfo.sync.isConnected)

    const dateTimeString = (date) => {
        if (!date) return t("dashboard_page_calendar_connect_waiting")
        return dayjs().format("YYYY.MM.DD HH:mm:ss")
    }

    const onChangeConnected = async () => {
        try {
            await axios.post(`${process.env.REACT_APP_API_SERVER}/user/connect`, {
                status: isConnected ? "off" : "on"
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            setConnected(!isConnected)
        } catch (err) {
            if (err.response) {
                console.log(err.response)
                toast.error(t("toast_err_something_wrong"))
            } else {
                console.error(err)
                toast.error(t("toast_err_can_not_connect_server"))
            }
        }
    }

    return (
        <div className="card">
            <div className={isConnected ? "switch" : "switch switch-off"} onClick={onChangeConnected}>
                <div className={isConnected ? "switch-in" : "switch-in switch-in-off"}></div>
            </div>
            <h3>
                {props.userInfo.sync.lastSyncStatus ? t("dashboard_page_calendar_connect_title_2") : t("dashboard_page_calendar_connect_title_1")}
            </h3>
            {props.userInfo.sync.lastSyncStatus ? <p>{t("dashboard_page_calendar_connect_error_guild")}: <a href={props.userInfo.sync.errorGuide.guide[i18n.language]} target="_blank" rel="noopener noreferrer">{props.userInfo.sync.errorGuide.name[i18n.language]}</a></p> : <></>}
            <p>{t("dashboard_page_calendar_connect_desc_1") + dateTimeString(props.userInfo.sync.lastSync)}</p>
        </div>
    )
}