import styled from 'styled-components'

export const InputDiv = styled.form`
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 12px 0px;
`

export const InputRow = styled.div`
    display: flex;
    justify-content: space-between;
`

export const InputRowText = styled.div`
    box-sizing: border-box;
    width: 200px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: end;
    padding-right: 12px;
`

export const InputRowInput = styled.input`
    width: 100%;
    background-color: #E7EFFC;
    border: 0;
    border-radius: 4px;
    outline: solid 2px #649DFF00;
    padding: 8px;
    box-sizing: border-box;
    
    &:focus {
        outline: solid 2px #649DFF;
    }
`

export const InputSubmitDiv = styled.div`
    margin-top: 8px;
    display: flex;
    justify-content: space-between;
    gap: 8px;
`

export const InputSubmit = styled.input`
    width: 100px;
    padding: 8px 12px;
    background-color: ${props => props.color || "#649DFF"};
    color: #ffffff;
    border: 0;
    border-radius: 4px;
    cursor: pointer;
`