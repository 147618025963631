import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import axios from "axios"
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { Link } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { CaretRight } from "phosphor-react"

import Header from "../Components/header";
import Connect from "./connect";
import PlanPage from "./plan/index";
import Footer from "../Components/footer";

import "../css/index.scss";
import { ReactComponent as GoogleCalendarLogo } from '../assets/google_calendar.svg';
import NotionLogo from '../assets/notion.png';
import calendar3D from "../assets/Calendar_perspective_matte_s.png"
import clock3D from "../assets/Clock_perspective_matte_s.png"
import note3D from "../assets/Notebook_perspective_matte_s.png"

const TitleDiv = styled.div`
    height: 67vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0px 8px;

    @media ( max-width: 767px ) {
        align-items: center;
        flex-direction: column;
    }  

    h1 {
        font-size: 44px;
        
        @media ( max-width: 767px ) {
            font-size: 32px;
            text-align: center;
        }   
    }

    p {
        font-size: 20px;
        @media ( max-width: 767px ) {
            font-size: 16px;
            text-align: center;
        }  
    }
`

const TitleDivTitle = styled.div`
    display: flex;
    flex-direction: column;
    @media ( max-width: 767px ) {
        justify-content: center;
        align-items: center;
    }  
`

const StartBtn = styled(Link)`
    display: flex;
    align-items: center;
    width: fit-content;
    margin-top: 32px;
    padding: 8px 32px;
    background: linear-gradient(to right, #649DFF, #9764FF);
    color: #ffffff;
    border-radius: 28px;
    text-decoration: none;
    cursor: pointer;

    @media ( max-width: 767px ) {
        margin-top: 16px;
    }
`

const TitleImgDiv = styled.div`
    width: 360px;
    height: 340px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
`

const TitleImgGoogleCalendar = styled(GoogleCalendarLogo)`
    top: 20px;
    left: 20px;
    width: 130px;
    height: 130px;
    position: absolute;
    border: solid 8px #ffffff;
    background-color: #ffffff;
    border-radius: 16px;
    z-index: 1;
`

const TitleImgNotion = styled.img`
    bottom: 20px;
    right: 20px;
    width: 140px;
    height: 140px;
    position: absolute;
    z-index: 1;
`

const TitleImgCircleKeyFrame = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(180deg);
    }
`

const TitleImgCircle = styled.div`
    height: 260px;
    width: 260px;
    border: dashed 4px ${props => props.color || "#9DA2AA"};
    border-radius: 160px;
    box-sizing: border-box;
    transform: rotate(0deg);
    animation: ${TitleImgCircleKeyFrame} 24s 0s infinite linear;
`

const TitleImage = (props) => {
    return (
        <TitleImgDiv>
            <TitleImgGoogleCalendar />
            <TitleImgNotion src={NotionLogo} />
            <TitleImgCircle color={props.color} />
        </TitleImgDiv>
    )
}

const Block5 = styled.div`
    margin-top: 200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 64px 48px;
    background: linear-gradient(90deg, #649DFF, #9764FF);
    color: #ffffff;
    border-radius: 16px;

    @media ( max-width: 767px ) {
        align-items: center;
        flex-direction: column;
        padding: 64px 0px;
    }

    h2 {
        font-size: 44px;
        
        @media ( max-width: 767px ) {
            font-size: 32px;
            text-align: center;
        }   
    }

    p {
        font-size: 20px;
        @media ( max-width: 767px ) {
            font-size: 16px;
            text-align: center;
        }  
    }
`

const StartBtnBlock5 = styled(Link)`
    display: flex;
    align-items: center;
    width: fit-content;
    margin-top: 32px;
    padding: 8px 32px;
    background: none;
    border: solid 2px #ffffff;
    color: #ffffff;
    border-radius: 28px;
    text-decoration: none;
    cursor: pointer;

    @media ( max-width: 767px ) {
        margin-top: 16px;
    }
`

function Index(props) {
    const { t, i18n } = useTranslation('translation')
    const [notionCode, setNotionCode] = useState("")
    const [userCount, setUserCount] = useState("000")
    const [review, setReview] = useState([{
        "id": 0,
        "emoji": "🧔",
        "review": "...",
        "author": "..."
    }, {
        "id": 0,
        "emoji": "🧔",
        "review": "...",
        "author": "..."
    }, {
        "id": 0,
        "emoji": "🧔",
        "review": "...",
        "author": "..."
    },
    ])

    const location = useLocation()
    const navigate = useNavigate()

    let userInfo
    if (localStorage.getItem('token')) userInfo = jwt_decode(localStorage.getItem('token'))

    useEffect(() => {
        // 노션에서 받아온 코드가 있으면 창을 엶
        const query = queryString.parse(location.search);
        if (query.code) {
            setNotionCode(query.code)
            navigate("/connect/notion")
        }

        // 메인 화면 데이터 가져옴
        ;(async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_SERVER}/landing`)
                setUserCount(response.data.calendarCount)
                setReview(response.data.review[i18n.language])
            } catch (err) {
                console.error(err)
                toast.error(t("index_toast_error_1"))
            }
        })()

    }, [props.location, t, i18n, location.search, navigate])

    return (
        <>
            <Header />
            <div id="index">
                <TitleDiv>
                    <TitleDivTitle>
                        <h1>{t("index_title_1")}<span className="underscore">{t("index_title_2")}</span>{t("index_title_3")}<br />{t("index_title_4")}</h1>
                        <p>{t("index_block_1_block_2_desc_1")}<br />{t("index_block_1_block_2_desc_2")}</p>
                        <StartBtn to={userInfo?.status === "finish" ? "/dashboard" : "/connect"}>{userInfo?.status === "finish" ? t("index_title_btn_2") : t("start")} <CaretRight size={16} weight="bold"/></StartBtn>
                    </TitleDivTitle>
                    <TitleImage />
                </TitleDiv>

                {/* <div className="titles">
                    <div className="opize-projects">Opize Project #1</div>
                    <h1>{t("index_title_1")}<span className="underscore">{t("index_title_2")}</span>{t("index_title_3")}<br />{t("index_title_4")}</h1>
                    <div className="btn-connect" onClick={() => navigate("/connect")}>
                        {userInfo?.status === "finish" ? t("index_title_btn_2") : t("index_title_btn_1")} <CaretRight />
                    </div>
                </div> */}


                {/* <div className="blocks-1">
                    <div className="block block-left">
                        <h2>{t("index_block_1_block_1_title_1")}<br />{t("index_block_1_block_1_title_2")}</h2>
                        <p>{t("index_block_1_block_1_desc_1")}<br />{t("index_block_1_block_1_desc_2")}</p>
                        <a className="btn" href={t("index_block_1_block_1_btn_link")} target="_blank" rel="noopener noreferrer">{t("index_block_1_block_1_btn")}<CaretRight /></a>
                        <div className="img">
                            <img src={calendar3D} alt="calendar icon" />
                        </div>
                    </div>
                    <div className="block-right">
                        <div className="block block-right-1">
                            <h3>{t("index_block_1_block_2_title_1")}<br />{t("index_block_1_block_2_title_2")}</h3>
                            <p>{t("index_block_1_block_2_desc_1")}<br />{t("index_block_1_block_2_desc_2")}</p>
                        </div>
                        <div className="block block-right-2">
                            <h3>{t("index_block_1_block_3_title_1")}<br /><span>{userCount}{t("index_block_1_block_3_title_2")}</span><br />{t("index_block_1_block_3_title_3")}</h3>
                            <p>{t("index_block_1_block_3_last_update")}{dayjs().format("YYYY.MM.DD HH:mm:ss")}</p>
                        </div>
                    </div>
                </div> */}

                <div className="blocks-2">
                    <div className="title">{t("index_block_2_title")}</div>

                    <div className="block block-1">
                        <div className="block-left">
                            <div className="subtitle">{t("index_block_2_block_1_subtitle")}</div>
                            <h3>{t("index_block_2_block_1_title_1")}<br />{t("index_block_2_block_1_title_2")}</h3>
                            <p>{t("index_block_2_block_1_desc_1")}<br />{t("index_block_2_block_1_desc_2")}</p>
                        </div>
                        <div className="block-right">
                            <img src={clock3D} alt="clock icon" />
                        </div>
                    </div>

                    <div className="block block-2">
                        <div className="block-left">
                            <div className="subtitle">{t("index_block_2_block_2_subtitle")}</div>
                            <h3>{t("index_block_2_block_2_title_1")}<br />{t("index_block_2_block_2_title_2")}</h3>
                            <p>{t("index_block_2_block_2_desc_1")}<br />{t("index_block_2_block_2_desc_2")}</p>
                        </div>
                        <div className="block-right">
                            <img src={calendar3D} alt="calendar icon" />
                        </div>
                    </div>

                    <div className="block block-3">
                        <div className="block-left">
                            <div className="subtitle">{t("index_block_2_block_3_subtitle")}</div>
                            <h3>{t("index_block_2_block_3_title_1")}<br />{t("index_block_2_block_3_title_2")}</h3>
                            <p>{t("index_block_2_block_3_desc_1")}<br />{t("index_block_2_block_3_desc_2")}</p>
                        </div>
                        <div className="block-right">
                            <img src={note3D} alt="note icon" />
                        </div>
                    </div>

                </div>

                <div className="blocks-3">
                    <div className="title">{t("index_block_3_title")}</div>
                    <div className="reviews">
                        <div className="review">
                            <div className="review-emoji">{review[0].emoji}</div>
                            <div className="review-content">{review[0].review}</div>
                            <div className="review-author">- {review[0].author}{t("index_block_3_review_author")}</div>
                        </div>

                        <div className="review">
                            <div className="review-emoji">{review[1].emoji}</div>
                            <div className="review-content">{review[1].review}</div>
                            <div className="review-author">- {review[1].author}{t("index_block_3_review_author")}</div>
                        </div>
                        <div className="review">
                            <div className="review-emoji">{review[2].emoji}</div>
                            <div className="review-content">{review[2].review}</div>
                            <div className="review-author">- {review[2].author}{t("index_block_3_review_author")}</div>
                        </div>
                    </div>
                    <div className="desc">{`${t("index_block_1_block_3_title_1")}`} <span>{userCount}{t("index_block_1_block_3_title_2")}</span> {`${t("index_block_1_block_3_title_3")}`}</div>
                </div>

                <div className="blocks-5">
                    <PlanPage />
                </div>

                <div className="blocks-4">
                    <div className="title">{t("index_block_4_title")}</div>
                    <div className="video">
                        <iframe src="https://www.youtube.com/embed/3hCP2R0n7R0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div>
                </div>

                <Block5>
                    <TitleDivTitle>
                        <div className="opize-projects">Opize Project #1</div>
                        <h2>{t("index_title_1")}{t("index_title_2")}{t("index_title_3")}<br />{t("index_title_4")}</h2>
                        <p>{t("index_block_1_block_1_desc_1")}<br />{t("index_block_1_block_1_desc_2")}</p>
                        <StartBtnBlock5 to={userInfo?.status === "finish" ? "/dashboard" : "/connect"}>{userInfo?.status === "finish" ? t("index_title_btn_2") : t("start")} <CaretRight size={16} weight="bold" /></StartBtnBlock5>
                    </TitleDivTitle>
                    <TitleImage color="white" />
                </Block5>
            </div>
            <Footer />
            {location.pathname.startsWith("/connect") ? <Connect notionCode={notionCode} setNotionCode={setNotionCode}/> : <></>}
        </>
    )
}

export default Index