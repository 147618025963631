import React, { useState, useEffect, useRef } from 'react';
import {  } from 'react-router';
import styled from 'styled-components';
import io from 'socket.io-client';
import axios from 'axios';

import Status from '../../Components/syncer/status'
import Header from '../../Components/admin/header'

import '../../css/dashboard.scss';

const socket = io.connect(process.env.REACT_APP_API_SERVER_SOCKET_IO)

const StatusGroup = styled.div`
    margin-top: 8px;
    gap: 8px;
    display: flex;
    flex-direction: column;
`

const LiveWork = (props) => {
    const workList = useRef({})
    const [work, setWork] = useState({})

    useEffect(()=>{
        socket.emit('auth', {
            token: localStorage.getItem("token")
        })

        socket.on('auth', msg => {
            console.log(msg)
        })

        socket.on('work', msg => {
            let data = JSON.parse(msg)
            workList.current[data.id] = data
            setWork({...workList.current})

            if (data.status === "finish" || data.status === "error") {
                setTimeout(() => {
                    delete workList.current[data.id]
                    setWork({...workList.current})
                }, 5000)
            }
        })
    }, [])

    return (
        <>
            <Header title="실시간 작업" />
            <StatusGroup>
                {
                    Object.keys(work).map(e => {
                        const userText = `${work[e].email}(@${work[e].id})`

                        let status
                        let statusText
                        switch (work[e].status) {
                            case "working":
                                status = "good"
                                statusText = "작업 중"
                                break;
                            
                            case "finish":
                                status = "finish"
                                statusText = "작업 완료"
                                break;
                            
                            case "warn":
                                status = "warn"
                                statusText = "문제 있음"
                                break;

                            case "error":
                                status = "error"
                                statusText = "에러"
                                break;
                        
                            default:
                                status = "good"
                                statusText = "상태 없음"
                                break;
                        }

                        let data
                            try {
                                data = JSON.stringify(work[e].data, null, 2)
                            } catch {
                                data = String(work[e].data)
                            } 

                        return (
                            <Status key={e} status={status} statusText={statusText} title={userText} subtitle={work[e].server} uuid={work[e].uuid} data={data}/>
                        )
                    })
                }
            </StatusGroup>
        </>
    )
}

export default function Dashboard(props) {
    const [serverStatus, setServerStatus] = useState({})

    useEffect(() => {
        (async () => {
            const serverRes = await axios.get(`${process.env.REACT_APP_API_SERVER}/syncer`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            setServerStatus(serverRes.data.data)
        })()
    }, [])


    return (
        <>
            <div id="dashboard-page">
                <h2>동기화봇 페이지</h2>
                <Header title="동기화봇 서버" />
                <StatusGroup>
                    {
                        Object.keys(serverStatus).map(e => {
                            let status
                            let statusText
                            switch (serverStatus[e].status) {
                                case "response":
                                    status = "good"
                                    statusText = "정상"
                                    break;
                                
                                case "response_but_error":
                                    status = "warn"
                                    statusText = "오류"
                                    break;
                                
                                case "not_response":
                                    status = "error"
                                    statusText = "응답없음"
                                    break;
                            
                                default:
                                    status = "good"
                                    statusText = "상태 없음"
                                    break;
                            }

                            let resText
                            try {
                                resText = JSON.stringify(serverStatus[e].response, null, 2)
                            } catch {
                                resText = String(serverStatus[e].response)
                            }

                            return (
                                <Status key={e} status={status} statusText={statusText} title={e} subtitle={serverStatus[e].url} data={resText}/>
                            )
                        })
                    }
                </StatusGroup>

                <LiveWork server={serverStatus} />

                {/* <Status Status="good" /> */}
            </div>
        </>
    )
}