import React from "react";
import { useTranslation } from 'react-i18next';

import Header from "../Components/header";
import styled from "styled-components";

const GuideDiv = styled.div`
    z-index: 999;
    width: 100%;
    max-width: 960px;
    margin: 0 auto;
`

const Iframe = styled.iframe`
    position: relative;
    border: 0;
    width: 100%;
    height: calc(100vh - 66px);
    margin-top: 57px;
    z-index: 999;
`

function Guide(props) {
    const { t } = useTranslation('translation')

    return (
        <>
            <Header />
                <GuideDiv>
                    <Iframe src={t("guide_page_link")}/>
                </GuideDiv>
        </>
    )
}

export default Guide