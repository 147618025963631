import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useForm } from "react-hook-form";

import '../../css/dashboard.scss';

import Header from '../../Components/admin/header';
import CodeBlock from '../../Components/admin/codeBlock';
import {InputDiv, InputRow, InputRowText, InputRowInput, InputSubmitDiv, InputSubmit} from "../../Components/admin/input"

const EditNotice = (props) => {
    const { register, handleSubmit, setValue } = useForm();
    const [btnStatus, setBtnStatus] = useState({text: "업데이트", color: "#649DFF"})

    useEffect(() => {
        setValue("title_ko", props.notice?.title["ko-KR"] || "")
        setValue("title_en", props.notice?.title["en-US"] || "")
        setValue("link_ko", props.notice?.link["ko-KR"] || "")
        setValue("link_en", props.notice?.link["en-US"] || "")
    }, [props.notice, setValue])

    const Edit = async (data) => {
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_SERVER}/admin/notice`, {
                title: {
                    ko: data.title_ko,
                    en: data.title_en
                },
                link: {
                    ko: data.link_ko,
                    en: data.link_en
                }
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            setBtnStatus({text: "업데이트 완료", color: "#0FBE9F"})
            setTimeout(() => {
                setBtnStatus({text: "업데이트", color: "#649DFF"})
            }, 2000)
            props.setNotice(res.data.data)
        } catch (err) {
            if (err.response) {
                console.error(err)
                    toast.warn("문제가 발생했습니다.")
            } else {
                console.error(err)
                toast.warn("문제가 발생했습니다.")
            }
        }
    }

    return (
        <InputDiv onSubmit={handleSubmit(Edit)}>

            <InputRow>
                <InputRowText>한국어 제목</InputRowText>
                <InputRowInput {...register("title_ko")} />
            </InputRow>

            <InputRow>
                <InputRowText>영어 제목</InputRowText>
                <InputRowInput {...register("title_en")} />
            </InputRow>

            <InputRow>
                <InputRowText>한국어 링크</InputRowText>
                <InputRowInput {...register("link_ko")} />
            </InputRow>

            <InputRow>
                <InputRowText>영어 링크</InputRowText>
                <InputRowInput {...register("link_en")} />
            </InputRow>

            <InputSubmitDiv>
                <div></div>
                <InputSubmit type={"submit"} value={btnStatus.text} color={btnStatus.color}/>
            </InputSubmitDiv>
        </InputDiv>
    )
}

export default function Notice(props) {
    const [notice, setNotice] = useState(props.notice)

    return (
        <>
            <div>
                <Header title="공지" />
                <CodeBlock value="공지" readOnly>
                    {JSON.stringify(notice, null, 2)}
                </CodeBlock>
                <EditNotice setNotice={setNotice} notice={notice} />
            </div>
        </>
    )
}