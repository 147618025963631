import styled from "styled-components"

const HeaderDiv = styled.div`
    margin-top: 16px;
    padding-bottom: 6px;
    border-bottom: solid 1px #CBCBCB;
`

const H3 = styled.h3`
    font-size: 20px;
`

const Header = (props) => {
    return (
        <HeaderDiv>
            <H3>{props.title}</H3>
        </HeaderDiv>
    )
}

export default Header