import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './Routes';
import reportWebVitals from './reportWebVitals';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./lang/i18n";
import Channel from "./Components/channelTalk"

import './css/style.scss'

import ReactGA from 'react-ga';
ReactGA.initialize('UA-158793073-11');
ReactGA.pageview(window.location.pathname + window.location.search);

console.log("개발자 코멘트: 개발자 도구를 여신 당신, 개발자이시군요!")

Channel.boot({
  "pluginKey": "7749bc67-021a-45c9-a614-2f3c8f22949a"
});

ReactDOM.render(
  <React.StrictMode>
    <ToastContainer
      position="bottom-left"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      pauseOnFocusLoss={false}
      draggable
      pauseOnHover={false}
    />
    <Routes />
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
