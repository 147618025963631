import React, { useEffect } from "react";
import { Globe, CaretRight } from "phosphor-react"
import styled from 'styled-components';
import { Link } from "react-router-dom";
import logo from '../assets/logo.png'
import jwt_decode from "jwt-decode";

import { useTranslation } from 'react-i18next'

const HeaderDivver = styled.div`
    z-index: 300;
    position: fixed;
    width: 100vw;
    left: 0;
    top: 0;
    box-sizing: border-box;
    background-color: #ffffff;
    border-bottom: 1px solid rgba(0,27,55,0.1);
`

const HeaderDiv = styled.div`
    padding: 12px 8px;
    max-width: 960px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
`

const HeaderDivRow = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`

const Logo = styled.img`
    height: 28px;
`

const Title = styled(Link)`
    text-decoration: none;
    color: #9764ff;
    font-size: 16px;
    font-weight: bold;
`

const Menu = styled(Link)`
    display: block;
    text-decoration: none;
    transition: 200ms;
    padding: 4px 12px;
    border-radius: 4px;
    color: #000000;

    &:hover {
        background-color: rgba(0,0,0,0.03);
    }

    @media ( max-width: 767px ) {
        display: none;
    }
`

const Lang = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    transition: 200ms;
    padding: 4px 12px;
    cursor: pointer;

    &:hover {
        background-color: rgba(0,0,0,0.04);
    }
`

const Login = styled(Link)`
    display: block;
    text-decoration: none;
    transition: 200ms;
    padding: 4px 12px;
    border-radius: 4px;
    color: #000000;

    @media ( max-width: 767px ) {
        display: none;
    }

    &:hover {
        background-color: rgba(0,0,0,0.03);
    }
`

const Start = styled(Link)`
    display: flex;
    align-items: center;
    padding: 6px 24px;
    background: linear-gradient(to right, #649DFF, #9764FF);
    color: #ffffff;
    border-radius: 28px;
    text-decoration: none;
    cursor: pointer;

    @media ( max-width: 767px ) {
        display: none;
    }
`

function Header() {
    const { t, i18n } = useTranslation('translation')

    let userInfo
    if (localStorage.getItem('token')) userInfo = jwt_decode(localStorage.getItem('token'))

    useEffect(() => {
        if (localStorage.getItem("lang")) {
            switch (localStorage.getItem("lang")) {
                case "ko_kr":
                    i18n.changeLanguage("ko-kr")
                    break

                case "en-US":
                    i18n.changeLanguage("en-US")
                    break

                default:
                    localStorage.removeItem("lang")
                    break
            }
        }
    }, [i18n])

    const changeLanguage = () => {
        const lang = i18n.language === "ko-KR" ? "en-US" : "ko-KR"
        localStorage.setItem("lang", lang)
        i18n.changeLanguage(lang)
    }

    return (
        <header>
            <HeaderDivver>
                <HeaderDiv>
                    <HeaderDivRow>
                        <Logo src={logo} alt="Calendar2notion logo" />
                        <Title to="/">Calendar2notion</Title>
                    </HeaderDivRow>
                    <HeaderDivRow>
                        <Menu to="/introduce" className="menu">{t("header_menu_introduce")}</Menu>
                        <Menu to="/guide"className="menu">{t("header_menu_guide")}</Menu>
                        <Menu to="/plan" className="menu">{t("header_menu_plan")}</Menu>

                        <Lang onClick={changeLanguage}>
                            <Globe size={20} />
                            {i18n.language === "ko-KR" ? "KOR" : "ENG"}
                        </Lang>

                        {userInfo?.status === "finish" ? <></> : <Login to="/connect">{t("sign_in")}</Login>}
                        <Start to={userInfo?.status === "finish" ? "/dashboard" : "/connect"}>{userInfo?.status === "finish" ? t("index_title_btn_2") : t("start")} <CaretRight size={16} /></Start>
                        {/* <div className={i18n.language === "ko-KR" ? "lang selected" : "lang"} onClick={() => changeLanguage("ko-KR")}>KOR</div>
                        <div className="divver">|</div>
                        <div className={i18n.language === "en-US" ? "lang selected" : "lang"} onClick={() => changeLanguage("en-US")}>ENG</div> */}
                    </HeaderDivRow>
                </HeaderDiv>
            </HeaderDivver>
        </header>
    )
}

export default Header